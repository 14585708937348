import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { ActiveModule, Media, Module, UserMe } from '@app/models';
import { ActiveModulesStoreSelectors } from '@app/root-store/active-modules-store';
import {
  MediasStoreActions,
  MediasStoreSelectors,
} from '@app/root-store/medias-store';
import { RootState } from '@app/root-store/root-state';
import { UserMePermissionsStoreSelectors } from '@app/root-store/user-me-permissions-store';
import { NetworkHandlerService } from '@app/services/network-handler.service';
import { TokenService } from '@core/services/token.service';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { setDefaultOptions } from 'date-fns';
import {
  de as deDateLocale,
  enUS as enUsDateLocale,
  es as esDateLocale,
  fr as frDateLocale,
  it as itDateLocale,
  ru as ruDateLocale,
} from 'date-fns/locale';
import { capitalize, get, intersection, max } from 'lodash';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ru';
import {
  de_DE,
  en_US,
  es_ES,
  fr_FR,
  it_IT,
  NzI18nService,
  ru_RU,
} from 'ng-zorro-antd/i18n';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { combineLatest as combineLatestFunction } from 'rxjs/internal/observable/combineLatest';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { SubSink } from 'subsink';

import {
  CoreActionTypes,
  LoadCheckinOutRequest,
  LoadCurrenciesRequest,
  LoadLanguagesRequest,
  ResetOnChangeLanguage,
  SelectGroup,
} from '../+state/core.actions';
import {
  selectAllPropertiesSubscriptionInfoWarnings,
  selectCheckinOut,
  selectCurrencyProperty,
  selectLanguages,
  selectSelectedGroup,
} from '../+state/core.reducer';
import { environment } from '../../../environments/environment';
import { UiConfig } from '../../config';
import { AddBillModalService } from '../../features/commons/add-bill-modal/services/add-bill-modal.service';
import { CrmActionTypes } from '../../features/commons/crm/+state/crm.actions';
import { AuthStoreActions } from '../../root-store/auth-store';
import {
  BookingWidgetsLinksStoreActions,
  BookingWidgetsLinksStoreSelectors,
} from '../../root-store/booking-widgets-links-store';
import { ChannelsStoreActions } from '../../root-store/channels-store';
import { DealersStoreActions } from '../../root-store/dealers-store';
import { InvoiceTypeStoreActions } from '../../root-store/invoice-type-store';
import { InvoicesStoreActions } from '../../root-store/invoices-store';
import { PaymentMethodsStoreActions } from '../../root-store/payment-methods-store';
import { PriceQuotationStoreActions } from '../../root-store/price-quotation-details-store';
import {
  PushNotificationsActions,
  PushNotificationsSelectors,
} from '../../root-store/push-notifications-store';
import { ReservationDetailsCustomActions } from '../../root-store/reservation-details-custom-store';
import { ReservationsStoreActions } from '../../root-store/reservations-store';
import {
  UserMeStoreActions,
  UserMeStoreSelectors,
} from '../../root-store/user-me-store';
import {
  UserPreferencesStoreActions,
  UserPreferencesStoreSelectors,
} from '../../root-store/user-preferences-store';
import {
  AppShellService,
  CloudMessagingService,
  FullscreenService,
  GlobalTopUpButtonService,
} from '../../services';
import { TitleService } from '../../services/title.service';
import { PropertiesSelectService } from '../../shared/properties-select/services/properties-select.service';
import { propertiesIsEqual } from '../helpers/properties-is-equal';
import { removeQueryString } from '../helpers/remove-query-string';
import { CustomMenuFormService } from '../services/custom-menu-form.service';

import { SelectProperty } from './../+state/core.actions';
import {
  selectAllGroups,
  selectAllProperties,
  selectPaymentMethods,
  selectSelectedProperty,
} from './../+state/core.reducer';
import { APP_COMMON_ROUTES, ICommonRoute } from './app.routes';

@Component({
  selector: 'by-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  animations: [
    trigger('sidebarAnimation', [
      state('expanded', style({ transform: 'translateX(0)' })),
      state('reduced', style({ transform: 'translateX(0)' })),
      state('closed', style({ transform: 'translateX(-300px)' })),
      transition('void => expanded', [
        style({ transform: 'translateX(-100%)' }),
        animate(400),
      ]),
      transition('expanded => reduced', [
        style({ transform: 'translateX(-100%)' }),
        animate(400),
      ]),
      transition('reduced => expanded', [
        style({ transform: 'translateX(-100%)' }),
        animate(400),
      ]),
      transition('void => reduced', [
        style({ transform: 'translateX(-100%)' }),
        animate(400),
      ]),
      transition('reduced => closed', [animate(150)]),
      transition('closed => expanded', [animate(150)]),
      transition('closed => reduced', [animate(150)]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppShellComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsPopover', {
    read: NzPopoverDirective,
    static: false,
  })
  notificationsPopoverDirective: NzPopoverDirective;

  selectedGroup: { id: number; name: string };

  selectedProperty: { id: number; name: string };

  propertiesIdsSelected: number[] = [];

  allGroups$ = this.store.select(selectAllGroups);

  allProperties$ = this.store.select(selectAllProperties);

  languages$ = this.store.select(selectLanguages);

  checkinOut$ = this.store.select(selectCheckinOut);

  propertiesSubscriptionInfoWarnings$ = this.store.select(
    selectAllPropertiesSubscriptionInfoWarnings,
  );

  notificationsCounter$ = this.store.pipe(
    select(PushNotificationsSelectors.selectCounter),
  );

  bookingWidgets$ = this.store.pipe(
    select(BookingWidgetsLinksStoreSelectors.selectAllItems),
    map((bookingWidgets) => {
      return bookingWidgets?.map((bookingWidget) => ({
        ...bookingWidget,
        complete_url: `https://${bookingWidget.dns}.beddy.${
          environment.production ? 'io' : 'dev'
        }`,
      }));
    }),
  );

  userMeImage$: Observable<Media>;

  paymentMethods$ = this.store.select(selectPaymentMethods);

  currency$ = this.store.select(selectCurrencyProperty);

  showHeader$ = this.appShellService.header.getShow();

  showFooter$ = this.appShellService.footer.getShow();

  online$ = this.networkHandler.online$;

  siderAnimationsState: 'expanded' | 'reduced' | 'closed';

  show = false;

  trigger = null;

  showUserPopover: boolean;

  isShowResponsiveMenu = false;

  showSideBar = true;

  propertyIdSelected: number = null;

  selectedLanguageIsoCode: string = null;

  triggerTemplate = null;

  userFullName = '';

  userMe: UserMe;

  commonRoutes = APP_COMMON_ROUTES;

  submenuCustomPopupOverlayIsOpen = false;

  currentCustomPopup: ICommonRoute;

  currentRoute = '';

  logoProperty: string;

  logoGroup: string;

  grouplogo$: Observable<Media>;

  linkCustom = [];

  notifications = [
    { message: 'saro ti ha scritto' },
    { message: 'pippo ti ha scritto' },
    { message: 'mario ti ha scritto' },
  ];

  sidebarWidth: number = UiConfig.SidebarWidth;

  groupsSelectPopoverVisible = false;

  isVisibleFoodAndBeverageModal = false;

  featuresThaCanBeSeen$ = null;

  isLanguageReady = false;

  verticalScrollFinished = false;
  verticalScroll = 0;

  userCanWrite$ = this.store.pipe(
    select(
      UserMePermissionsStoreSelectors.selectUserMeCanWriteInFeature('property'),
    ),
    map((permissions) => Object.values(permissions).some((value) => value)),
  );

  CDN_URL = environment.cdnUrl;

  topUpButtonHidden$ = this.globalTopUpButtonService.isHidden();

  isFullscreen: boolean;

  private currentDevice: 'desktop' | 'tablet' | 'mobile' = 'desktop';

  isAdmin = false;

  activeModules: Record<Module, ActiveModule>;

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private titleService: TitleService,
    private addBillModal: AddBillModalService,
    private customMenu: CustomMenuFormService,
    private i18n: NzI18nService,
    public token: TokenService,
    private networkHandler: NetworkHandlerService,
    private actions$: Actions,
    private propertiesSelectService: PropertiesSelectService,
    private activatedRoute: ActivatedRoute,
    private cloudMessagingService: CloudMessagingService,
    private appShellService: AppShellService,
    private globalTopUpButtonService: GlobalTopUpButtonService,
    private cookieService: CookieService,
    private fullscreen: FullscreenService,
  ) {
    this.isAdmin = !!this.token.isAdmin();
    // Se è un admin non richiedo l'attivazione del cloud messaging
    if (!this.isAdmin) {
      this.cloudMessagingService.init();
    }

    // questa pezza serve per evitare il problema di aggiornare dati di altre property dello stesso gruppo
    const { selectedPropertyId } = this.activatedRoute.snapshot.queryParams;

    if (selectedPropertyId) {
      this.store.dispatch(
        new SelectProperty({ propertyId: +selectedPropertyId }),
      );
    }
    // fine pezza
  }

  ngOnInit() {
    const { innerWidth } = window;
    if (innerWidth <= 765) {
      this.showResponsiveMenu('mobile');
    }
    if (innerWidth > 765 && innerWidth <= 1024) {
      this.showResponsiveMenu('tablet');
    }
    if (innerWidth > 1024) {
      this.showResponsiveMenu('desktop');
    }

    this.subs.add(
      this.activatedRoute.queryParams.subscribe(({ propertyGroupId }) => {
        if (propertyGroupId) {
          this.store.dispatch(new SelectGroup({ groupId: +propertyGroupId }));
        }
      }),
    );

    this.subs.add(
      this.propertiesSelectService.selectedProperties.subscribe(
        (properties: number[]) => {
          if (propertiesIsEqual(this.propertiesIdsSelected, properties)) {
            return;
          }
          this.propertiesIdsSelected = properties;
          this.store.dispatch(
            new LoadCheckinOutRequest({ propertiesIds: properties }),
          );
        },
      ),
    );

    this.subs.add(
      combineLatestFunction([
        this.propertiesSelectService.selectedProperties,
        this.store.pipe(
          select(
            UserMePermissionsStoreSelectors.selectUserMePermissionsFeatureProperties(
              'bookingWidget',
            ),
          ),
        ),
      ])
        .pipe(
          map(([properties, enabledProperties]) => {
            return intersection(properties, enabledProperties);
          }),
          filter((properties) => !!(properties && properties.length)),
          distinctUntilChanged((a, b) => propertiesIsEqual(a, b)),
        )
        .subscribe((propertiesIds) => {
          this.loadBookingWidgets(propertiesIds);
        }),
    );

    this.subs.add(
      this.allProperties$.subscribe((properties) => {
        const propertyIds = properties.map(({ id }) => id);

        this.featuresThaCanBeSeen$ = this.store.pipe(
          select(
            UserMePermissionsStoreSelectors.selectUserMeFeaturesThatCanBeSeen(
              propertyIds,
            ),
          ),
        );
      }),
    );

    this.subs.add(
      this.store
        .pipe(
          select(UserMePermissionsStoreSelectors.selectUserMePermissionstate),
          combineLatest(
            this.allProperties$,
            this.store.pipe(
              select(ActiveModulesStoreSelectors.selectActiveModulesData),
            ),
          ),
        )
        .subscribe(([permissions, allProperties, activeModules]) => {
          if (!permissions.userMePermissions || allProperties.length === 0) {
            return;
          }
          this.activeModules = activeModules;

          const propertiesIds = allProperties.map(({ id }) => id);

          this.commonRoutes = APP_COMMON_ROUTES.filter((route) => {
            if (environment.production && route.onlyDev) {
              return false;
            }

            if (!this.isAdmin && route.onlyAdmin) {
              return false;
            }

            return true;
          })
            .filter((route) =>
              this.filterRoute(
                route,
                propertiesIds,
                permissions.propertyPermissions,
              ),
            )
            .map((route) =>
              this.mapRoute(
                route,
                propertiesIds,
                permissions.propertyPermissions,
              ),
            )
            .map((route) => {
              if (!route.children) {
                return route;
              }
              return {
                ...route,
                children: route.children.length ? route.children : null,
              };
            })
            .filter((route) => route.children !== null);
        }),
    );

    this.subs.add(
      this.store.select(selectSelectedProperty).subscribe((data) => {
        if (!data) {
          return;
        }
        this.selectedProperty = data;

        const { id } = this.selectedProperty;

        if (this.propertyIdSelected === id) {
          return;
        }
        this.propertyIdSelected = id;

        this.store.dispatch(
          new MediasStoreActions.LoadRequestAction({
            type: 'property',
            type_id: this.propertyIdSelected,
          }),
        );
      }),
    );

    this.subs.add(
      this.store
        .select(MediasStoreSelectors.selectMediasType('property'))
        .subscribe((medias) => {
          this.logoProperty = null;
          if (!medias) {
            return;
          }
          const mediaFind = (medias[this.propertyIdSelected] || []).find(
            ({ genre }) => genre === 'logo',
          );
          if (mediaFind) {
            this.logoProperty = get(mediaFind, 'formats_size.small', '');
          }
        }),
    );

    this.subs.add(
      this.store.select(selectSelectedGroup).subscribe((data) => {
        if (!data) {
          return;
        }
        this.selectedGroup = data;
        this.store.dispatch(
          new MediasStoreActions.LoadRequestAction({
            type: 'property_group',
            type_id: this.selectedGroup.id,
          }),
        );
        this.grouplogo$ = this.store.pipe(
          select(
            MediasStoreSelectors.selectAllMediasTypeId(
              'property_group',
              this.selectedGroup.id,
            ),
          ),
          map(
            (medias: Media[]) =>
              medias.length &&
              (medias || []).find((media) => media.genre === 'logo'),
          ),
        );
      }),
    );

    this.subs.add(
      this.store
        .select(UserMeStoreSelectors.selectData)
        .subscribe((userMe: UserMe) => {
          if (!userMe) {
            return;
          }
          this.userMe = userMe;
          const { full_name, id, lang_iso_code } = userMe;
          this.userFullName = full_name;
          this.store.dispatch(
            new MediasStoreActions.LoadRequestAction({
              type: 'user',
              type_id: id,
            }),
          );
          this.userMeImage$ = this.store.pipe(
            select(MediasStoreSelectors.selectAllMediasTypeId('user', id)),
            map((medias: Media[]) => medias.length && medias[0]),
          );
          this.onLanguageChange(lang_iso_code);
          this.store.dispatch(ChannelsStoreActions.loadRequest());
          this.store.dispatch(new LoadCurrenciesRequest());
          this.store.dispatch(InvoiceTypeStoreActions.loadRequest());
          this.store.dispatch(DealersStoreActions.loadRequest());
          this.store.dispatch(
            new PaymentMethodsStoreActions.LoadRequestAction({}),
          );
          this.store.dispatch(
            new UserPreferencesStoreActions.LoadRequestAction({
              userId: id,
            }),
          );
          this.store.dispatch(
            new InvoicesStoreActions.LoadStatusSdiInfoRequestAction(),
          );
          this.store.dispatch(new LoadLanguagesRequest());

          this.store.dispatch(PushNotificationsActions.loadCounterRequest());
        }),
    );

    this.subs.add(
      this.router.events.subscribe((_event) => {
        if (_event instanceof NavigationStart) {
          const { url } = this.router;
          if (
            _event.url.includes('/reservation/') &&
            !url.includes('/reservation/')
          ) {
            this.store.dispatch(
              ReservationsStoreActions.setLoading({ loading: true }),
            );
          }
        }
        if (_event instanceof NavigationEnd) {
          this.store.dispatch(
            ReservationsStoreActions.setLoading({ loading: false }),
          );
          this.currentRoute = removeQueryString(_event.urlAfterRedirects);
          this.openCorrectSubmenu(this.currentRoute);
        }
      }),
    );

    this.currentRoute = removeQueryString(this.router.url);
    this.openCorrectSubmenu(this.currentRoute);

    this.subs.add(
      this.store
        .select(UserPreferencesStoreSelectors.selectUserPreferencesData)
        .subscribe((preference) => {
          if (!Object.keys(preference || {}).length) {
            return;
          }

          this.linkCustom = [];
          Object.keys(preference['customLinkMenu'] || {}).forEach((key) => {
            const link: any = preference['customLinkMenu'][key];
            if (link) {
              this.linkCustom.push(link);
            }
          });
          this.linkCustom = this.linkCustom
            .slice()
            .sort((a, b) => a.order - b.order);
        }),
    );

    /**
     * @description sottoscrizione alle azioni che al success devono ricaricare
     * le informazioni di checkin/checkout giornalieri
     */
    this.subs.add(
      this.actions$
        .pipe(
          ofType(
            CoreActionTypes.CreateNewReservationSuccess, //creazione da tableau
            CrmActionTypes.ProcessReservationSuccess, //creazione da crm
            ReservationDetailsCustomActions.updateCheckInOutSuccess, //aggiornamento stato di arrivo
            ReservationDetailsCustomActions.updateCheckinCheckoutDateSuccess, //cambio date prenotazione
            ReservationDetailsCustomActions.setGuestCheckinCheckoutSuccess, //checkin degli ospiti
            ReservationDetailsCustomActions.updateStatusSuccess, //aggiornamento stato prenotazione
            ReservationDetailsCustomActions.addReservationAccommodationSuccess, //aggiunta di una camera da una prenotazione
            ReservationsStoreActions.deleteSuccess, //eliminazione prenotazione
            ReservationsStoreActions.deleteAccommodationSuccess, //eliminazione di una camera da una prenotazione
            PriceQuotationStoreActions.ActionTypes.CONFIRM_OPTION_SUCCESS, //conferma di un preventivo
          ),
        )
        .subscribe(() =>
          this.store.dispatch(
            new LoadCheckinOutRequest({
              propertiesIds: this.propertiesIdsSelected,
            }),
          ),
        ),
    );

    this.subs.add(
      this.fullscreen.changes.subscribe((isFullscreen) => {
        this.isFullscreen = isFullscreen;
        this.siderAnimationsState = isFullscreen ? 'reduced' : 'expanded';
      }),
    );
  }

  createModal() {
    this.addBillModal.open();
  }

  createCustomMenuFormService() {
    this.customMenu.createComponentModal();
  }

  showResponsiveMenu(device: 'desktop' | 'tablet' | 'mobile') {
    this.currentDevice = device;

    if (device === 'desktop') {
      this.isShowResponsiveMenu = false;
      this.showSideBar = true;
      this.siderAnimationsState = 'expanded';
    }
    if (device === 'tablet') {
      this.isShowResponsiveMenu = false;
      this.showSideBar = true;
      this.siderAnimationsState = 'reduced';
    }
    if (device === 'mobile') {
      this.isShowResponsiveMenu = true;
      this.showSideBar = false;
    }
  }

  onLogout() {
    this.store.dispatch(AuthStoreActions.logoutRequest({}));
  }

  siderResponsive(siderState: string) {
    switch (siderState) {
      case 'expanded': {
        this.siderAnimationsState = 'expanded';
        break;
      }
      case 'reduced': {
        this.siderAnimationsState = 'reduced';
        break;
      }
      case 'closed': {
        this.showSideBar = false;
        break;
      }
      default: {
        break;
      }
    }
  }

  onUpdateLanguage(newLanguageIsoCode: string) {
    if (newLanguageIsoCode === this.userMe.lang_iso_code) {
      return;
    }

    this.store.dispatch(
      UserMeStoreActions.updateRequest({
        request: {
          ...this.userMe,
          property_id: null,
          lang_iso_code: newLanguageIsoCode,
        },
        noLoading: false,
      }),
    );
  }

  onLanguageChange(newLanguageIsoCode: string) {
    if (this.selectedLanguageIsoCode === newLanguageIsoCode) {
      return;
    }

    /*La condizione sottostante fa fare un refresh della pagina.
    Cio è necessario perché alcune funzionalità, come i pipe per le date,
     non vanno automaticamente in base alla localizzazione. Per questo motivo, 
     è necessario eseguire un refresh ogni volta che si cambia lingua*/

    if (this.selectedLanguageIsoCode !== null) {
      location.reload();

      return;
    }

    this.selectedLanguageIsoCode = newLanguageIsoCode;

    this.isLanguageReady = false;

    let locale: Promise<any>;

    switch (newLanguageIsoCode) {
      case 'it': {
        this.i18n.setDateLocale(itDateLocale);
        this.i18n.setLocale(it_IT);
        setDefaultOptions({ locale: itDateLocale });
        locale = import(
          '../../../../node_modules/@angular/common/locales/it.mjs'
        );
        break;
      }
      case 'es': {
        this.i18n.setDateLocale(esDateLocale);
        this.i18n.setLocale(es_ES);
        setDefaultOptions({ locale: esDateLocale });
        locale = import(
          '../../../../node_modules/@angular/common/locales/es.mjs'
        );
        break;
      }
      case 'fr': {
        this.i18n.setDateLocale(frDateLocale);
        this.i18n.setLocale(fr_FR);
        setDefaultOptions({ locale: frDateLocale });
        locale = import(
          '../../../../node_modules/@angular/common/locales/fr.mjs'
        );

        break;
      }
      case 'de': {
        this.i18n.setDateLocale(deDateLocale);
        this.i18n.setLocale(de_DE);
        setDefaultOptions({ locale: deDateLocale });
        locale = import(
          '../../../../node_modules/@angular/common/locales/de.mjs'
        );
        break;
      }
      case 'ru': {
        this.i18n.setDateLocale(ruDateLocale);
        this.i18n.setLocale(ru_RU);
        setDefaultOptions({ locale: ruDateLocale });
        locale = import(
          '../../../../node_modules/@angular/common/locales/ru.mjs'
        );
        break;
      }
      default: {
        this.i18n.setDateLocale(enUsDateLocale);
        this.i18n.setLocale(en_US);
        setDefaultOptions({ locale: enUsDateLocale });
        locale = import(
          '../../../../node_modules/@angular/common/locales/en.mjs'
        );
      }
    }

    locale.then((lang) => {
      registerLocaleData(lang.default);

      this.store.dispatch(new ResetOnChangeLanguage());

      moment.locale(this.selectedLanguageIsoCode);

      this.translate.use(this.selectedLanguageIsoCode);

      setTimeout(() => {
        this.isLanguageReady = true;
      });
    });
  }

  scrollOnTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  sidebarStateController() {
    if (this.currentDevice === 'desktop') {
      if (this.siderAnimationsState === 'expanded') {
        this.siderAnimationsState = 'reduced';
        return;
      }
      if (this.siderAnimationsState === 'reduced') {
        this.siderAnimationsState = 'closed';
        return;
      }
      if (this.siderAnimationsState === 'closed') {
        this.siderAnimationsState = 'expanded';
        return;
      }
    }
    if (this.currentDevice === 'tablet') {
      if (this.siderAnimationsState === 'reduced') {
        this.siderAnimationsState = 'closed';
        return;
      }
      if (this.siderAnimationsState === 'closed') {
        this.siderAnimationsState = 'reduced';
        return;
      }
    }
  }

  onChangeGroupSelected(groupId) {
    if (groupId === null) {
      return;
    }
    this.store.dispatch(new SelectGroup({ groupId }));
    this.router.navigate(['/'], { replaceUrl: true, relativeTo: this.route });
    localStorage.removeItem('tableau_toolbox_options');
  }

  onChangePropertySelected(propertyId) {
    if (propertyId === null) {
      return;
    }
    this.store.dispatch(new SelectProperty({ propertyId }));
  }

  get isGroupSelectDisabled() {
    const matches = window.location.href.match(/\/[(0-9)]+|[new]{3}\/?/gi);
    if (!matches) {
      return false;
    }
    return false;
  }

  commonOpenHandler(link: ICommonRoute): void {
    this.commonRoutes
      .filter((route) => link !== route)
      .forEach((routeFiltered) => {
        routeFiltered.dropdownIsOpen = false;
      });
  }

  openCorrectSubmenu(urlToCompare: string, routes = this.commonRoutes) {
    if (this.siderAnimationsState === 'reduced') {
      return;
    }

    this.submenuCustomPopupOverlayIsOpen = false;

    routes = routes.map((routeFiltered) => {
      if (!routeFiltered.isDropdown && routeFiltered.searchKey) {
        routeFiltered.searchKey.forEach((key) => {
          if (urlToCompare.includes(key)) {
            this.titleService.set(
              capitalize(this.translate.instant(routeFiltered.label)),
            );
          }
        });
      }

      routeFiltered.dropdownIsOpen = false;
      routeFiltered.isActive = false;

      if (!routeFiltered.children) {
        return { ...routeFiltered };
      }

      this.openCorrectSubmenu(urlToCompare, routeFiltered.children);

      const isRouteActive = routeFiltered.children.some((child) => {
        return child.isActive;
      });

      routeFiltered.dropdownIsOpen = isRouteActive;
      routeFiltered.isActive = isRouteActive;

      routeFiltered.children.forEach((child) => {
        if (child.searchKey) {
          routeFiltered.dropdownIsOpen =
            routeFiltered.dropdownIsOpen ||
            child.searchKey.some((key) => {
              const dropdownIsOpen =
                (child.searchOperatorIsEqual && urlToCompare === key) ||
                (!child.searchOperatorIsEqual && urlToCompare.includes(key));

              if (dropdownIsOpen) {
                if (!routeFiltered.withSiderPopup) {
                  routeFiltered.dropdownIsOpen = true;
                }
                child.isActive = true;
                routeFiltered.isActive = true;
                this.titleService.set(
                  capitalize(this.translate.instant(child.label)),
                );
              }

              return dropdownIsOpen;
            });
        }
      });

      return routeFiltered;
    });
  }

  closeCustomSubmenu(): void {
    this.submenuCustomPopupOverlayIsOpen = false;
    this.currentCustomPopup.dropdownIsOpen = false;
  }

  onRemoveImpersonate() {
    this.token.removeImpersonate();
  }

  scrollToCustomBox() {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    });
  }

  ngOnDestroy() {
    this.store.dispatch(BookingWidgetsLinksStoreActions.resetState());
    this.subs.unsubscribe();
  }

  dropCustomLink(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.linkCustom, event.previousIndex, event.currentIndex);
    this.linkCustom.forEach((link, order) => {
      this.store.dispatch(
        new UserPreferencesStoreActions.UpdateRequestAction({
          preferences: {
            options: {
              customLinkMenu: {
                [link.keyCombination]: { ...link, order },
              },
            },
          },
        }),
      );
    });
  }

  onNavigate(url: string) {
    this.router.navigate([url]);
  }

  animationHandler(animation, action: 'start' | 'done') {
    if (action === 'start' && animation.toState === 'closed') {
      this.sidebarWidth = 50;
    }
    if (action === 'done' && animation.toState === 'closed') {
      this.sidebarWidth = 0;
    }
    if (action === 'start' && animation.fromState === 'closed') {
      this.sidebarWidth = UiConfig.SidebarWidth;
    }
  }

  isFoodAndBeverage(link) {
    if (link === '/food-beverage') {
      this.isVisibleFoodAndBeverageModal = true;
    }
  }

  handleCancel() {
    this.isVisibleFoodAndBeverageModal = false;
  }

  onWindowScroll() {
    const { scrollY } = window;
    const { scrollHeight, offsetHeight } = document.body;

    this.verticalScroll = scrollY;
    this.verticalScrollFinished =
      Math.trunc(scrollHeight - scrollY - offsetHeight) < 50;
  }

  loadBookingWidgets(properties: number[]) {
    if ((properties || []).length === 0) {
      return;
    }
    this.store.dispatch(
      BookingWidgetsLinksStoreActions.loadRequest({ properties }),
    );
  }

  filterRoute(
    route: ICommonRoute,
    propertiesIds: number[],
    propertyPermissions: any,
  ) {
    if (route.children) {
      return true;
    }

    if (!route.featurePermission || !route.featurePermission.length) {
      return false;
    }

    if (environment.production && route.onlyDev) {
      return false;
    }

    if (!this.isAdmin && route.onlyAdmin) {
      return false;
    }
    const propertiesWithAccess = propertiesIds.filter((propertyId: number) => {
      const levels = route.featurePermission.map((permission) => {
        if (permission === 'any') {
          return 2;
        }
        if (!propertyPermissions[permission]) {
          return 0;
        }
        return propertyPermissions[permission][propertyId];
      });
      return max(levels) >= (route.permissionLevel || 1);
    });

    if (!propertiesWithAccess || !propertiesWithAccess.length) {
      return false;
    }

    if (!route.requiredModule) {
      return true;
    }

    const propertiesWithModule = propertiesWithAccess.filter((propertyId) => {
      return get(
        this.activeModules,
        `[${route.requiredModule}].properties[${propertyId}].status`,
      );
    });

    if (!propertiesWithModule || !propertiesWithModule.length) {
      return false;
    }

    return true;
  }

  mapRoute(
    route: ICommonRoute,
    propertiesIds: number[],
    propertyPermissions: any,
  ) {
    let mappedRoute = route;
    if (!route.children) {
      return mappedRoute;
    }

    mappedRoute = {
      ...mappedRoute,
      children: mappedRoute.children.map((childrenRoute) =>
        this.mapRoute(childrenRoute, propertiesIds, propertyPermissions),
      ),
    };

    return {
      ...mappedRoute,
      children: mappedRoute.children.filter((child) => {
        const propertiesWithAccess = propertiesIds.filter(
          (propertyId: number) => {
            if (!child.featurePermission?.length) {
              return false;
            }

            if (environment.production && child.onlyDev) {
              return false;
            }

            if (!this.isAdmin && child.onlyAdmin) {
              return false;
            }

            const levels = child.featurePermission.map((permission) => {
              if (permission === 'any') {
                return 2;
              }
              if (!propertyPermissions[permission]) {
                return 0;
              }
              return propertyPermissions[permission][propertyId];
            });
            return max(levels) >= (child.permissionLevel || 1);
          },
        );

        if (!propertiesWithAccess || !propertiesWithAccess.length) {
          return false;
        }

        if (!child.requiredModule || !this.activeModules) {
          return true;
        }

        if (!Object.keys(this.activeModules || {}).length) {
          return true;
        }

        const propertiesWithModule = propertiesWithAccess.filter(
          (propertyId) => {
            return get(
              this.activeModules,
              `[${child.requiredModule}].properties[${propertyId}].status`,
            );
          },
        );

        if (!propertiesWithModule || !propertiesWithModule.length) {
          return false;
        }

        return true;
      }),
    };
  }

  get fromMobileApp() {
    return this.cookieService.get('from_mobile_app');
  }
}
