import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import moment from 'moment';
import { filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { DateFormatterService } from '../../core/services/date-formatter.service';
import { BillFormHelpers } from '../../helpers/bill-form-helpers';
import {
  showChildrenRangesConditionMapConstant,
  showPeriodConditionMapConstant,
  showQuantityAndRateConditionMapConstant,
} from '../../helpers/bills-helpers';
import { floatRound } from '../../helpers/float-round';
import {
  AddonEstimate,
  BillDetails,
  ChildrenRange,
  PlaceVatQuote,
} from '../../models';
import { EditBillsRequest } from '../../models/requests/edit-bills.request';
import {
  EstimateAddonPriceStoreActions,
  EstimateAddonPriceStoreSelectors,
} from '../../root-store/estimate-addon-price-store';
import { RootState } from '../../root-store/root-state';
import { ReservationDetailsBillsService } from '../../services/reservation-details-bills.service';

@Component({
  selector: 'by-edit-bill-form',
  templateUrl: './edit-bill-form.component.html',
  styleUrls: ['./edit-bill-form.component.scss'],
})
export class EditBillFormComponent implements OnInit, OnDestroy {
  @Input() set billId(billId: number) {
    if (!billId) {
      return;
    }

    this.onLoadBillDetails(billId);
  }

  @Input() guests: Array<{ id: number; name: string }>;

  @Input() childrenRanges: ChildrenRange[] = [];

  @Input() currencySymbol: string;

  @Input() vatQuotes: PlaceVatQuote[];

  @Input() arrivalDate: Date;

  @Input() departureDate: Date;

  loadingPrice$ = this.store.pipe(
    select(EstimateAddonPriceStoreSelectors.selectIsLoading),
  );

  showQuantityAndRateConditionMapConstant =
    showQuantityAndRateConditionMapConstant;
  showChildrenRangesConditionMapConstant =
    showChildrenRangesConditionMapConstant;
  showPeriodConditionMapConstant = showPeriodConditionMapConstant;

  loading = false;

  bill: BillDetails;

  form = this.formBuilder.group({
    base_price: [null, [Validators.required]],
    price: [null, [Validators.required]],
    qty: [null, [Validators.required, Validators.min(1)]],
    customer_buyer_id: [null, [Validators.required]],
    customer_id: [null, [Validators.required]],
    discount_type_id: [null, [Validators.required]],
    discount_value: [null],
    vat_quote_id: [null, [Validators.required]],
    date: [null, [Validators.required]],
    adults: [null, [Validators.min(1)]],
    children_number: [null],
    children: this.formBuilder.array([]),
    deposit: [null],
  });

  private subs = new SubSink();
  private estimatesSubs = new SubSink();

  private estimate$ = this.store.pipe(
    select(EstimateAddonPriceStoreSelectors.selectTotalAddons),
    filter((estimate) => !!(estimate && estimate[this.bill?.addon_id])),
    map((estimate) => estimate[this.bill?.addon_id]),
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private billsService: ReservationDetailsBillsService,
    private store: Store<RootState>,
    private dateFormatter: DateFormatterService,
  ) {}

  customerIsInGuestsArray = (customerId: number) => {
    return !!(this.guests || []).find(({ id }) => id === customerId);
  };

  customerOptionsClass = (customerId: number) => {
    return this.customerIsInGuestsArray(customerId)
      ? 'color--azure bolder'
      : '';
  };

  customerOptionsSort = ({ id: customerIdA }, { id: customerIdB }) => {
    const aIsInArray = this.customerIsInGuestsArray(customerIdA);
    const bIsInArray = this.customerIsInGuestsArray(customerIdB);

    if (aIsInArray && bIsInArray) {
      return 0;
    }

    if (!aIsInArray && !bIsInArray) {
      return 0;
    }

    if (aIsInArray && !bIsInArray) {
      return -1;
    }

    if (!aIsInArray && bIsInArray) {
      return 1;
    }
  };

  get value(): EditBillsRequest {
    const children = Object.values(
      groupBy(this.form.value.children, 'property_children_range_id'),
    ).map((childrenRangesWithSameId) => ({
      property_has_children_range_id:
        childrenRangesWithSameId[0].property_children_range_id,
      qty: childrenRangesWithSameId.length,
    }));

    return {
      [this.bill.id]: {
        ...this.dateFormatter.formatObjectDates(this.form.value),
        children,
        discount_value: this.form.value.discount_value || 0,
        deposit: this.form.value.deposit || 0,
        price: this.priceWithoutDiscount,
      },
    };
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get dateAndQuantityAreDisabled(): boolean {
    return (
      this.bill?.production_type === 'stay' ||
      this.bill?.production_type === 'tax' ||
      this.bill?.price_type === 'xbook'
    );
  }

  get priceWithoutDiscount(): number {
    const { base_price, qty } = this.form.value;
    return floatRound(base_price * qty);
  }

  get childrenControl(): UntypedFormArray {
    return this.form.get('children') as UntypedFormArray;
  }

  get showQuantityAndUnitPrice(): boolean {
    return this.showQuantityAndRateConditionMapConstant[this.bill?.price_type];
  }

  get showAdultsAndChildren(): boolean {
    return this.showChildrenRangesConditionMapConstant[this.bill?.price_type];
  }

  currencyFormatter = (value: string) =>
    `${this.currencySymbol || ''} ${floatRound(+value || 0)}`;

  disabledDate = (date: Date) => {
    return (
      moment(date).isBefore(this.arrivalDate, 'days') ||
      moment(date).isAfter(this.departureDate, 'days')
    );
  };

  ngOnInit() {
    this.subscribeToFormChanges();
    this.listenChildrenNumberControlChanges();

    this.subs.add(
      this.estimate$.subscribe((estimate) => this.applyEstimate(estimate)),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(EstimateAddonPriceStoreActions.resetState());
  }

  get isPercentageDiscount(): boolean {
    return this.form.value.discount_type_id === 5;
  }

  reset() {
    this.guests = [];
    this.estimatesSubs.unsubscribe();
  }

  private applyEstimate(estimate: AddonEstimate) {
    const { discount_value, discount_type_id } = this.form.value;
    this.form.patchValue(
      {
        ...estimate,
        total: BillFormHelpers.getTotalPrice(
          estimate.unit_price,
          estimate.quantity,
          discount_type_id,
          discount_value,
        ),
      },
      { emitEvent: false },
    );
  }

  private onLoadBillDetails(billId: number) {
    this.loading = true;

    this.subs.add(
      this.billsService
        .loadBillDetails(billId)
        .subscribe(({ data }: IResponseSuccess<BillDetails[]>) => {
          this.bill = { ...data[0], label: this.getLabel(data[0]) };

          this.addBillBuyerInRoomGuests({
            id: this.bill.customer_buyer.id,
            name: `${this.bill.customer_buyer.name} ${this.bill.customer_buyer.surname}`,
          });

          // Patch bill data into form
          this.setFormWithBillData();

          // Listen for estimate
          this.listenForEstimateRequest();

          // Setting validators
          const priceControl = this.form.get('price');
          priceControl.clearValidators();
          priceControl.setValidators([
            Validators.min(+this.bill.invoiced_price),
          ]);
          priceControl.updateValueAndValidity();

          this.loading = false;
        }),
    );
  }

  private subscribeToFormChanges() {
    this.subs.add(
      this.form.get('qty').valueChanges.subscribe((qty) => {
        const { base_price, discount_value, discount_type_id } =
          this.form.value;

        const price = BillFormHelpers.getTotalPrice(
          base_price,
          qty,
          discount_type_id,
          discount_value,
        );

        this.updateTotalPrice(price);
      }),
    );

    this.subs.add(
      this.form.get('base_price').valueChanges.subscribe((base_price) => {
        const { qty, discount_value, discount_type_id } = this.form.value;

        const price = BillFormHelpers.getTotalPrice(
          base_price,
          qty,
          discount_type_id,
          discount_value,
        );

        this.updateTotalPrice(price);
      }),
    );

    this.subs.add(
      this.form
        .get('discount_value')
        .valueChanges.subscribe((discount_value) => {
          const { base_price, qty, discount_type_id } = this.form.value;

          const price = BillFormHelpers.getTotalPrice(
            base_price,
            qty,
            discount_type_id,
            discount_value,
          );

          this.updateTotalPrice(price);
        }),
    );

    this.subs.add(
      this.form.get('discount_type_id').valueChanges.subscribe(() => {
        const { base_price, qty } = this.form.value;

        const price = BillFormHelpers.getTotalPrice(base_price, qty);

        this.form.patchValue({ discount_value: 0 }, { emitEvent: false });
        this.updateTotalPrice(price);
      }),
    );

    this.subs.add(
      this.form.get('price').valueChanges.subscribe((price) => {
        const {
          qty,
          discount_type_id,
          discount_value,
          price: priceFromForm,
        } = this.form.value;

        if (priceFromForm === price) {
          return;
        }

        const base_price = BillFormHelpers.getUnitPrice(
          price,
          qty,
          discount_type_id,
          discount_value,
        );

        this.form.patchValue({ base_price }, { emitEvent: false });
      }),
    );
  }

  private listenChildrenNumberControlChanges() {
    this.subs.add(
      this.form
        .get('children_number')
        .valueChanges.subscribe((childrenNumber) => {
          while (childrenNumber < this.childrenControl.length) {
            this.childrenControl.removeAt(this.childrenControl.length - 1);
          }

          while (childrenNumber > this.childrenControl.length) {
            this.childrenControl.push(
              this.formBuilder.group({
                property_children_range_id: this.childrenRanges[0]?.id,
                qty: 1,
              }),
            );
          }
        }),
    );
  }

  private listenForEstimateRequest() {
    this.estimatesSubs.add(
      this.form
        .get('adults')
        .valueChanges.subscribe((adults) =>
          this.estimateAddon({ ...this.form.value, adults }),
        ),
    );

    this.estimatesSubs.add(
      this.childrenControl.valueChanges.subscribe((children) =>
        this.estimateAddon({ ...this.form.value, children }),
      ),
    );

    this.estimatesSubs.add(
      this.form
        .get('date')
        .valueChanges.subscribe((date) =>
          this.estimateAddon({ ...this.form.value, date }),
        ),
    );
  }

  private estimateAddon(formValue) {
    if (!this.bill?.addon_id) {
      return;
    }

    this.store.dispatch(
      EstimateAddonPriceStoreActions.loadRequest({
        addon_id: this.bill.addon_id,
        reservation_accommodation_id: this.bill.reservation_accommodation_id,
        date_ranges: [
          {
            from: this.dateFormatter.toServerFormat(formValue.date),
            to: this.dateFormatter.toServerFormat(formValue.date),
          },
        ],
        adults: formValue.adults,
        quantity: this.showQuantityAndUnitPrice ? formValue.qty : 1,
        children_ranges: formValue.children.map((child) => ({
          property_has_children_range_id: child.property_children_range_id,
          qty: child.qty,
        })),
      }),
    );
  }

  private addBillBuyerInRoomGuests(buyer: { id: number; name: string }) {
    const guests = this.guests || [];

    if (!!guests.find(({ id }) => id === buyer.id)) {
      return;
    }

    this.guests = [buyer, ...(this.guests || [])];
  }

  private setFormWithBillData() {
    const adults =
      (this.bill.guest_details || []).find(
        ({ property_children_range_id }) => !property_children_range_id,
      )?.qty || null;

    this.childrenControl.clear();
    (this.bill.guest_details || [])
      .filter(({ property_children_range_id }) => !!property_children_range_id)
      .forEach(({ property_children_range_id, qty }) => {
        for (let i = 0; i < qty; i++) {
          this.childrenControl.push(
            this.formBuilder.group({ property_children_range_id, qty: 1 }),
          );
        }
      });

    const deposit = (this.bill.payments || []).reduce(
      (sum, payment) => (sum += +payment.amount),
      0,
    );

    this.form.patchValue(
      {
        ...this.bill,
        base_price: floatRound(+this.bill.total_price / +this.bill.qty),
        price: +this.bill.total_price - +this.bill.discounted_price,
        qty: +this.bill.qty,
        customer_buyer_id: this.bill.customer_buyer.id,
        customer_id: +this.bill.customer.id,
        discount_type_id: this.bill.discount_type_id,
        discount_value: +this.bill.discount_value,
        vat_quote_id: this.bill.vat_quote_id || 1,
        date: this.bill.date ? new Date(this.bill.date) : null,
        adults,
        children_number: this.childrenControl.length,
        deposit,
      },
      { emitEvent: false },
    );
  }

  private updateTotalPrice(price: number) {
    const control = this.form.get('price');
    this.form.patchValue({ price }, { emitEvent: false });
    control.markAsDirty();
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  private getLabel(bill: BillDetails): string {
    if (bill.production_type !== 'stay') {
      return bill.label;
    }

    return `${bill.accommodation_name} (${bill.tableau_label || 'OB'})`;
  }
}
